import * as React from "react"

export default class Intro extends React.Component {
  render() {
    return (
      <section className="intro">
        <p className="intro-date-1">
          <span>
            01
          </span>
          <span>
            07
          </span>
          <span>
            21
          </span>
        </p>
        <p className="intro-subtext">
          <span>
            Currently:
          </span>
          <span>
            designer,
          </span>
          <span>
            Abstract
          </span>
        </p>
        <p className="intro-text-1">
          Yamilah Atallah
        </p>
        <p className="intro-text-2">
          designer
        </p>
        <p className="intro-text-3">
          & dev—
        </p>
        <p className="intro-text-4">
          eloper
        </p>
        <p className="intro-text-5">
          San Fran
        </p>
        <p className="intro-text-6">
          Bay Area
        </p>
        <p className="intro-text-7">
          2015—2021
        </p>
        <p className="intro-date-2">
          <span>
            01
          </span>
          <span>
            07
          </span>
          <span>
            21
          </span>
        </p>
      </section>
    )
  }
}
